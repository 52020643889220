:root {
    --green-color: #89B72B;
    --orange-color: #F8B01D;
    --black-color: #000000;
    --red-color: #e82424;
}

.cerification-model input {
    border-radius: 5px;
    border: 1px solid #e4d7d7;
    padding: 10px 10px;
}

.cl-green {
    background-color: var(--green-color) !important;
}

.cl-orange {
    background-color: var(--orange-color) !important;
}

.btn-orange {
    background-color: var(--orange-color) !important;
    color: var(--black-color) !important;
    margin: 0px 15px !important;
}

.text-orange {
    color: var(--orange-color);
}

.text-green {
    color: var(--green-color);
}

.btn-danger {
    background-color: var(--red-color) !important;
    color: var(--black-color) !important;
    margin: 0px 15px !important;
}

.btn-green {
    background-color: var(--green-color) !important;
    color: var(--black-color) !important;
    margin: 0px 15px !important;
}

.btn-green-disable {
    background-color: #dbeea8 !important;
    color: var(--black-color) !important;
    margin: 0px 15px !important;
}

.btn-linear {
    background: rgb(137, 183, 43);
    background: linear-gradient(261deg, rgba(137, 183, 43, 1) 0%, rgba(248, 176, 29, 1) 100%);
    color: var(--black-color) !important;
    margin: 0px 15px !important;
}

.chart tspan {
    text-transform: capitalize;

}

.MuiTable-root th,
.MuiTable-root tr {
    white-space: nowrap;
}

.css-119926t-MuiPaper-root-MuiPopover-paper {
    width: 150px !important;
}

.special-audit {
    font-size: 13px;
    background: #f8b01d;
    color: black;
    padding: 2px 8px;
    border-radius: 10px;
    text-align: center;
    margin-left: 20px;
}