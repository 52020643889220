/* Loader.css */
.loader-container {
    display: flex;
    position: fixed;
    width: 100%;
    z-index: 9999;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #0000003d;

}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}