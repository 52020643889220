.logo-img {
    height: 50px;
    width: 50px;
    object-fit: cover;
    border-radius: 5px;
}

.font-18 {
    font-size: 20px !important;
}

.title a {
    text-decoration: none;
    padding: 0.5rem 1.5rem 0.5rem 1.5rem;
    font-size: 18px;
    color: #212529;
    font-weight: 600;
    border-radius: 25px 25px 0px 0px;
    margin-bottom: 0px;
    text-transform: capitalize;
    cursor: pointer;
}

.title a.active {
    background-color: #F8B01D;
}